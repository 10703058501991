import Featurette from "../components/Featurette"
import FeaturetteTextContent from "../components/Featurette/FeaturetteTextContent"
import { PageHead } from '../components/PageHead';

const FourOhFour = (props) => {
  return (
    <>
      <PageHead />
      <Featurette image="/images/not-found.jpg" imagePlacement="RIGHT">
        <FeaturetteTextContent
          title="404"
          body="Page not found / Seite nicht gefunden"
          buttonText="Boxes / Boxen"
          link={"/our-boxes"}
          isCTA={true}
        />
      </Featurette>
    </>
  )
}


export default FourOhFour